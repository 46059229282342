import { data } from "autoprefixer";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Notification from "../../../components/notifications";

export default {
  name: "FormulirPermohonanRahn",
  components: {},
  data() {
    return {
      dataForm: {
        kodeCabang: "",
        namaPemohon: "",
        namaNIF: "",
        alamatSesuaiKtp: "",
        noHp: "",
        tujuanGadai: "",
        jangkaWaktuGadai: "",
        uangPembiayaan: "",
        noSbr: "",
        ktrngJmnnRAHN: "",
        penaksir1: "",
        penaksir2: "",
        kadivPembiayaan: "",
      },
    };
  },
};
